
import { Component, Mixins } from 'vue-property-decorator';
import SidenaviSearchdate from './';
const Words: Dictionary = {
  label: 'Search by date',
  datelabel: 'Check-in dates',
  year: '年',
  month: '月',
  from: 'to',
  pleoplelabel: 'Guests',
  night: 'day',
  many: '',
  room: 'Room(s)',
  buttonlabel: 'Check availability',
  note: '* Vacancy calendar applies to reservations for Mitsui Garden Hotels and The Celestine Hotels.<br>You can search for areailabilities for reservations up to 3 nights.<br>For reservations up to 4 nights or more,please use the "Reservation" button below.',
};
@Component
export default class SidenaviSearchdateJa extends Mixins(SidenaviSearchdate) {
  private words = Words;
}

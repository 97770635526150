
import { Component, Mixins } from 'vue-property-decorator';
// @ is an alias to /src
import FormKeywordRecommend from '@/components/form/FormKeywordRecommend/FormKeywordRecommendEn.vue';
import SidenaviSearchkeyword from './';
const Words: Dictionary = {
  label: 'Enter keyword',
};
@Component({
  components: {
    FormKeywordRecommend,
  },
})
export default class SidenaviSearchkeywordJa extends Mixins(SidenaviSearchkeyword) {
  private words = Words;
}


import { Component, Mixins } from 'vue-property-decorator';
import SidenaviSearcharea from './';
import NaviSerchnaviAreaLinks from '@/components/navi/NaviSerchnaviAreaLinks/NaviSerchnaviAreaLinksEn.vue';
const Words: Dictionary = {
  label: 'Search by destination',
};
@Component({
  components: {
    NaviSerchnaviAreaLinks,
  },
})
export default class SidenaviSearchareaEn extends Mixins(SidenaviSearcharea) {
  private words = Words;
}

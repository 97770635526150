import { Component, Mixins } from "vue-property-decorator";
import ViewsHasSidenavi from "@/mixins/ViewsHasSidenavi";
import SidenaviSearchdate from "@/components/navi/sidenavi/SidenaviSearchdate/SidenaviSearchdateEn.vue";
import SidenaviSearchkeyword from "@/components/navi/sidenavi/SidenaviSearchkeyword/SidenaviSearchkeywordEn.vue";
import SidenaviSearcharea from "@/components/navi/sidenavi/SidenaviSearcharea/SidenaviSearchareaEn.vue";
@Component({
  components: {
    SidenaviSearchdate,
    SidenaviSearchkeyword,
    SidenaviSearcharea,
  },
})
export default class ViewsHasSidenaviEn extends Mixins(ViewsHasSidenavi) {}
